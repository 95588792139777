import React from 'react';
import { RouteNode } from '../../../@types';

const List = React.lazy(() => import('../../../pages/holiday/List'));
const Create = React.lazy(() => import('../../../pages/holiday/Create'));
const Detail = React.lazy(() => import('../../../pages/holiday/Detail'));

export const holiday: RouteNode[] = [
  {
    label: 'Holiday',
    child: null,
    path: 'holiday',
    component: List,
    requiredPermissions: 'VIEW',
  },
  {
    label: '',
    child: null,
    path: 'holiday/create',
    component: Create,
    requiredPermissions: 'CREATE',
  },
  {
    label: '',
    child: null,
    path: 'holiday/detail/:id',
    component: Detail,
    requiredPermissions: 'VIEW',
  },
];
