import React from 'react';
import { RouteNode } from '../../@types';

const StatutoryCreate = React.lazy(
  () => import('../../pages/legalEntity/statutory/Create')
);
const StatutoryUpdate = React.lazy(
  () => import('../../pages/legalEntity/statutory/Update')
);
const StatutoryCorrection = React.lazy(
  () => import('../../pages/legalEntity/statutory/Correction')
);

const StatutoryRoute: RouteNode[] = [
  {
    label: '',
    path: 'legal-entity/detail/:id/statutories/create',
    component: StatutoryCreate,
    child: null,
    requiredPermissions: 'CREATE',
  },
  {
    label: '',
    path: 'legal-entity/detail/:id/statutories/update/:groupId',
    component: StatutoryUpdate,
    child: null,
    requiredPermissions: 'UPDATE',
  },
  {
    label: '',
    path: 'legal-entity/detail/:id/statutories/correction/:groupId',
    component: StatutoryCorrection,
    child: null,
    requiredPermissions: 'CORRECTION',
  },
];

export default StatutoryRoute;
