import { lazy } from 'react';
import { RouteNode } from '../../../@types';

const List = lazy(() => import('../../../pages/attendanceProcess/List'));
const Create = lazy(() => import('../../../pages/attendanceProcess/Create'));
const Detail = lazy(() => import('../../../pages/attendanceProcess/Detail'));

export const attendanceProcess: RouteNode[] = [
  {
    label: 'Attendance Process',
    child: null,
    path: 'attendance-process',
    component: List,
    requiredPermissions: 'VIEW',
  },
  {
    label: '',
    child: null,
    path: 'attendance-process/create',
    component: Create,
    requiredPermissions: 'CREATE',
  },
  {
    label: '',
    child: null,
    path: 'attendance-process/detail/:id',
    component: Detail,
    requiredPermissions: 'VIEW',
  },
];
