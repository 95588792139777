import React from 'react';
import { RouteNode } from '../../../@types';

const List = React.lazy(() => import('../../../pages/claimType/List'));
const Create = React.lazy(() => import('../../../pages/claimType/Create'));
const Detail = React.lazy(() => import('../../../pages/claimType/Detail'));

export const claimType: RouteNode[] = [
  {
    label: 'Claim Type',
    child: null,
    path: 'claim-type',
    component: List,
    requiredPermissions: 'VIEW',
  },
  {
    label: '',
    child: null,
    path: 'claim-type/create',
    component: Create,
    requiredPermissions: 'CREATE',
  },
  {
    label: '',
    path: 'claim-type/detail/:id',
    component: Detail,
    child: null,
    requiredPermissions: 'VIEW',
  },
];
