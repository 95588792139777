import { lazy } from 'react';
import { RouteNode } from '../../../@types';

const List = lazy(() => import('../../../pages/overtime/List'));
const Create = lazy(() => import('../../../pages/overtime/Create'));
const Details = lazy(() => import('../../../pages/overtime/Details'));
const Assign = lazy(() => import('../../../pages/overtime/Assign'));

export const overtime: RouteNode[] = [
  {
    label: 'Overtime',
    child: null,
    path: 'overtime',
    component: List,
    requiredPermissions: 'VIEW',
  },
  {
    label: '',
    child: null,
    path: 'overtime/create',
    component: Create,
    requiredPermissions: 'CREATE',
  },
  {
    label: '',
    child: null,
    path: 'overtime/detail/:id',
    component: Details,
    requiredPermissions: 'VIEW',
  },
  {
    label: '',
    child: null,
    path: 'overtime/assign/:sessionId',
    component: Assign,
    requiredPermissions: 'CREATE',
  },
];
