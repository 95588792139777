export const extractModuleNameFromPath = (path: string) => {
  if (path.includes('setup-payroll')) {
    return 'pay_group';
  }
  if (path.includes('accumulator')) {
    return 'accumulator';
  }
  if (path.includes('export')) {
    return 'export';
  }
  if (path.includes('import')) {
    return 'import';
  }
  if (path.includes('standard-report')) {
    return 'standard_report';
  }
  if (path.includes('chart-of-accounts')) {
    return 'chart_of_account';
  }
  if (path.includes('master-data/tax-location')) {
    return 'tax_location';
  }
  if (path.includes('master-data/employment-type')) {
    return 'employment_type';
  }
  if (path.includes('master-data/cost-center')) {
    return 'cost_center';
  }
  if (path.includes('master-data/bank')) {
    return 'bank';
  }
  if (path.includes('organization/file')) {
    return 'file';
  }
  if (path.includes('organization/work-location')) {
    return 'work_location';
  }
  if (path.includes('organization/organization-unit')) {
    return 'organization_unit';
  }
  if (path.includes('organization/job-position')) {
    return 'job_position';
  }
  if (path.includes('organization/job-level')) {
    return 'job_level';
  }
  if (path.includes('organization/job-grade')) {
    return 'job_grade';
  }
  if (path.includes('organization/organization-structure')) {
    return 'organization_structure';
  }
  if (path.includes('organization/legal-entity')) {
    return 'legal_entity';
  }
  if (path.includes('time/configuration/time-off-items')) {
    return 'time_off_item';
  }
  if (path.includes('time/configuration/time-items')) {
    return 'time_item';
  }
  if (path.includes('time/configuration/policy')) {
    return 'policy';
  }
  if (path.includes('time/configuration/overtime-item')) {
    return 'overtime_item';
  }
  if (path.includes('time/configuration/attendance-status')) {
    return 'attendance_status';
  }
  if (path.includes('time/configuration/attendance-machine')) {
    return 'attendance_machine';
  }
  if (path.includes('time/setup/time-off')) {
    return 'time_off';
  }
  if (path.includes('time/setup/schedule')) {
    return 'schedule';
  }
  if (path.includes('time/setup/shift')) {
    return 'shift';
  }
  if (path.includes('time/setup/overtime')) {
    return 'overtime';
  }
  if (path.includes('time/setup/job-site')) {
    return 'job_site';
  }
  if (path.includes('time/setup/holiday')) {
    return 'holiday';
  }
  if (path.includes('time/time-off-request/request')) {
    return 'time_off_request';
  }
  if (path.includes('time/overtime/overtime-request')) {
    return 'overtime_request';
  }
  if (path.includes('time/attendance/daily-attendance')) {
    return 'daily_attendance';
  }
  if (path.includes('time/attendance/attendance-process')) {
    return 'attendance_process';
  }
  if (path.includes('time/work-schedule')) {
    return 'work_schedule';
  }
  if (path.includes('time/analytics')) {
    return 'analytics';
  }
  if (path.includes('claim/claim-process')) {
    return 'claim_process';
  }
  if (path.includes('claim/claim-request')) {
    return 'claim_request';
  }
  if (path.includes('claim/setup/claim')) {
    return 'claim';
  }
  if (path.includes('claim/configuration/claim-type')) {
    return 'claim_type';
  }
  if (path.includes('payroll/configuration/value-rounding')) {
    return 'value_rounding';
  }
  if (path.includes('payroll/configuration/statutory')) {
    return 'statutory';
  }
  if (path.includes('payroll/configuration/payslip-template')) {
    return 'payslip_template';
  }
  if (path.includes('payroll/configuration/formula')) {
    return 'formula';
  }
  if (path.includes('payroll/configuration/exchange-rate')) {
    return 'exchange_rate';
  }
  if (path.includes('payroll/configuration/bank-file')) {
    return 'bank_file';
  }
  if (path.includes('payroll/setup/pay-item')) {
    return 'pay_item';
  }
  if (path.includes('payroll/payroll-run')) {
    return 'run_payroll';
  }
  if (path.includes('employee')) {
    return 'employee';
  }
  if (path.includes('//')) {
    return 'home';
  }
  if (path.includes('custom-field')) {
    return 'custom_field';
  }
  if (path.includes('user-interface')) {
    return 'user_interface';
  }
  if (path.includes('security/user-access')) {
    return 'user_access';
  }
  if (path.includes('security/access-control')) {
    return 'access_control';
  }
  if (path.includes('security/ess-access-control')) {
    return 'ess_access_control';
  }
  return '';
};
