import React from 'react';
import { RouteNode } from '../@types';
import { Home02 } from '../components/atom/icon';

const DashboardPages = React.lazy(() => import('../pages/dashboard/Dashboard'));

const Details = React.lazy(() => import('../pages/news/Details'));

const Create = React.lazy(() => import('../pages/news/Create'));

const Update = React.lazy(() => import('../pages/news/Update'));

const Correction = React.lazy(() => import('../pages/news/Correction'));

const ReleaseNotes = React.lazy(
  () => import('../pages/dashboard/ReleaseNotes')
);

export const Dashboard: RouteNode[] = [
  {
    label: 'Home',
    icon: Home02,
    child: null,
    path: '/',
    component: DashboardPages,
    requiredPermissions: 'VIEW',
  },
  {
    label: '',
    path: '/news/detail/:id',
    component: Details,
    child: null,
    requiredPermissions: 'VIEW',
  },
  {
    label: '',
    path: '/news/create',
    component: Create,
    child: null,
    requiredPermissions: 'CREATE_NEWS',
  },
  {
    label: '',
    path: '/news/update/:id',
    component: Update,
    child: null,
    requiredPermissions: 'UPDATE_NEWS',
  },
  {
    label: '',
    path: '/news/correction/:id',
    component: Correction,
    child: null,
    requiredPermissions: 'CORRECTION_NEWS',
  },
  {
    label: '',
    path: '/release-notes',
    component: ReleaseNotes,
    child: null,
    requiredPermissions: 'VIEW',
  },
];
