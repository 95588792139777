import { lazy } from 'react';
import { RouteNode } from '../../../@types';

const List = lazy(() => import('../../../pages/dailyAttendance/List'));

export const dailyAttendance: RouteNode[] = [
  {
    label: 'Daily Attendance',
    child: null,
    path: 'daily-attendance',
    component: List,
    requiredPermissions: 'VIEW',
  },
];
