import React from 'react';
import { RouteNode } from '../../@types';

const CreateBank = React.lazy(
  () => import('../../pages/legalEntity/CreateBank')
);
const UpdateBank = React.lazy(
  () => import('../../pages/legalEntity/UpdateBank')
);
const CorrectionBank = React.lazy(
  () => import('../../pages/legalEntity/CorrectionBank')
);

const BankRoute: RouteNode[] = [
  {
    label: '',
    path: 'legal-entity/:id/bank-create',
    component: CreateBank,
    child: null,
    requiredPermissions: 'CREATE',
  },
  {
    label: '',
    path: 'legal-entity/:id/bank-update/:groupId',
    component: UpdateBank,
    child: null,
    requiredPermissions: 'UPDATE',
  },
  {
    label: '',
    path: 'legal-entity/:id/bank-correction/:groupId',
    component: CorrectionBank,
    child: null,
    requiredPermissions: 'CORRECTION',
  },
];

export default BankRoute;
