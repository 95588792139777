import { lazy } from 'react';
import { RouteNode } from '../../../@types';

const List = lazy(() => import('../../../pages/policy/List'));
const CreateAttendancePolicy = lazy(
  () => import('../../../pages/policy/attendance/Create')
);
const DetailsAttendancePolicy = lazy(
  () => import('../../../pages/policy/attendance/Details')
);
const CreateEssPolicy = lazy(() => import('../../../pages/policy/ess/Create'));
const DetailsEssPolicy = lazy(
  () => import('../../../pages/policy/ess/Details')
);

export const policy: RouteNode[] = [
  {
    label: 'Policy',
    child: null,
    path: 'policy',
    component: List,
    requiredPermissions: 'VIEW',
  },
  {
    label: '',
    child: null,
    path: 'policy/attendance/create',
    component: CreateAttendancePolicy,
    requiredPermissions: 'CREATE',
  },
  {
    label: '',
    child: null,
    path: 'policy/attendance/detail/:id',
    component: DetailsAttendancePolicy,
    requiredPermissions: 'VIEW',
  },
  {
    label: '',
    child: null,
    path: 'policy/ess/create',
    component: CreateEssPolicy,
    requiredPermissions: 'CREATE',
  },
  {
    label: '',
    child: null,
    path: 'policy/ess/detail/:id',
    component: DetailsEssPolicy,
    requiredPermissions: 'VIEW',
  },
];
