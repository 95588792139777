import { lazy } from 'react';
import { RouteNode } from '../../../@types';

const Create = lazy(() => import('../../../pages/attendanceMachine/Create'));
const List = lazy(() => import('../../../pages/attendanceMachine/List'));
const Detail = lazy(() => import('../../../pages/attendanceMachine/Detail'));
const Update = lazy(() => import('../../../pages/attendanceMachine/Update'));

export const attendanceMachine: RouteNode[] = [
  {
    label: 'Attendance Machine',
    child: null,
    path: 'attendance-machine',
    component: List,
    requiredPermissions: 'VIEW',
  },
  {
    label: '',
    child: null,
    path: 'attendance-machine/create',
    component: Create,
    requiredPermissions: 'CREATE',
  },
  {
    label: '',
    child: null,
    path: 'attendance-machine/detail/:id',
    component: Detail,
    requiredPermissions: 'VIEW',
  },
  {
    label: '',
    child: null,
    path: 'attendance-machine/update/:id',
    component: Update,
    requiredPermissions: 'UPDATE',
  },
];
