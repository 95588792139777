import { lazy } from 'react';
import { RouteNode } from '../../../@types';

const List = lazy(() => import('../../../pages/overtimeItem/List'));
const Create = lazy(() => import('../../../pages/overtimeItem/Create'));
const Detail = lazy(() => import('../../../pages/overtimeItem/Detail'));

export const overtimeItem: RouteNode[] = [
  {
    label: 'Overtime Item',
    child: null,
    path: 'overtime-item',
    component: List,
    requiredPermissions: 'VIEW',
  },
  {
    label: '',
    child: null,
    path: 'overtime-item/create',
    component: Create,
    requiredPermissions: 'CREATE',
  },
  {
    label: '',
    child: null,
    path: 'overtime-item/detail/:id',
    component: Detail,
    requiredPermissions: 'VIEW',
  },
];
