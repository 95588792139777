import React from 'react';
import { RouteNode } from '../../@types';

const Update = React.lazy(
  () => import('../../pages/employee/deduction/Update')
);
const Create = React.lazy(
  () => import('../../pages/employee/deduction/Create')
);
const Correction = React.lazy(
  () => import('../../pages/employee/deduction/Correction')
);

const deduction: RouteNode[] = [
  {
    label: '',
    path: 'employee/detail/:id/deduction/update',
    component: Create,
    child: null,
    requiredPermissions: 'CREATE',
  },
  {
    label: '',
    path: 'employee/detail/:id/deduction/update/:deductionId',
    component: Update,
    child: null,
    requiredPermissions: 'UPDATE',
  },
  {
    label: '',
    path: 'employee/detail/:id/deduction/correction/:deductionId',
    component: Correction,
    child: null,
    requiredPermissions: 'CORRECTION',
  },
];

export default deduction;
