import React from 'react';
import { RouteNode } from '../../../@types';
import TERHarianDetail from '../../../components/organism/payrollConfigurationStatutory/detail/id/TERHarianDetails';

const UnderConstruction = React.lazy(
  () => import('../../../pages/UnderConstruction')
);
const List = React.lazy(
  () => import('../../../pages/payrollConfigurationStatutory/List')
);
const DetailHealthCare = React.lazy(
  () =>
    import(
      '../../../components/organism/payrollConfigurationStatutory/detail/id/SocialHealtcare'
    )
);
const DetailSocialSecurity = React.lazy(
  () =>
    import(
      '../../../components/organism/payrollConfigurationStatutory/detail/id/SocialSecurity'
    )
);
const TaxDetails = React.lazy(
  () =>
    import(
      '../../../components/organism/payrollConfigurationStatutory/detail/id/TaxDetails'
    )
);
const TERDetails = React.lazy(
  () =>
    import(
      '../../../components/organism/payrollConfigurationStatutory/detail/id/TERDetails'
    )
);
const TERHarianDetails = React.lazy(
  () =>
    import(
      '../../../components/organism/payrollConfigurationStatutory/detail/id/TERHarianDetails'
    )
);
const severancePay = React.lazy(
  () =>
    import(
      '../../../components/organism/payrollConfigurationStatutory/detail/id/SeverancePayDetails'
    )
);

const NonTaxableIncomeDetails = React.lazy(
  () =>
    import(
      '../../../components/organism/payrollConfigurationStatutory/detail/id/NonTaxableIncomeDetails'
    )
);

const statutory: RouteNode[] = [
  {
    label: 'Statutory',
    path: 'statutory',
    child: null,
    component: List,
    requiredPermissions: 'VIEW',
  },

  {
    label: '',
    path: 'statutory/social-healthcare/detail',
    child: null,
    component: DetailHealthCare,
    requiredPermissions: 'VIEW',
  },

  {
    label: '',
    path: 'statutory/social-security/detail',
    child: null,
    component: DetailSocialSecurity,
    requiredPermissions: 'VIEW',
  },

  {
    label: '',
    path: 'statutory/tax/detail',
    component: TaxDetails,
    child: null,
    requiredPermissions: 'VIEW',
  },
  {
    label: '',
    path: 'statutory/ter/detail',
    component: TERDetails,
    child: null,
    requiredPermissions: 'VIEW',
  },
  {
    label: '',
    path: 'statutory/ter-harian/detail',
    component: TERHarianDetails,
    child: null,
    requiredPermissions: 'VIEW',
  },
  {
    label: '',
    path: 'statutory/severancePay/detail',
    component: severancePay,
    child: null,
    requiredPermissions: 'VIEW',
  },
  {
    label: '',
    path: 'statutory/non-taxable-income/detail',
    component: NonTaxableIncomeDetails,
    child: null,
    requiredPermissions: 'VIEW',
  },
];

export default statutory;
