import { Navigate } from 'react-router-dom';
import { getCookie } from '../utils/cookies';
import { RouteNode } from '../@types';
import { useAppSelector } from '../store';
import { extractModuleNameFromPath } from '../utils/urlSplitter';

interface PrivateRouteProps {
  children?: any;
  node?: RouteNode;
}

const PrivateRoute = (props: PrivateRouteProps) => {
  const { children, node } = props;
  const { dataAccess } = useAppSelector(state => state.userAccess);

  // 1. Check if user is logged in (has token)
  const isLoggedIn = Boolean(getCookie('app__refresh_token'));
  if (!isLoggedIn) {
    // Not logged in? Send to login (or wherever is appropriate)
    return <Navigate to="/login" />;
  }

  // 2. If user is logged in but dataAccess is still loading, show something
  if (!dataAccess || Object.keys(dataAccess).length === 0) {
    return <div>Loading...</div>;
  }

  // 3. Ensure the route’s requiredPermission is satisfied
  // const resource = node?.label?.toLowerCase() || '';
  const resource = extractModuleNameFromPath(node?.path || '');

  const neededPerm = node?.requiredPermissions;

  if (!resource || !neededPerm) {
    // If your route somehow doesn't have a resource or neededPerm,
    // you can decide to allow or disallow by default:
    return <div>no permissions or resource provided</div>;
  }

  const userPermissions = dataAccess[resource] || [];
  const hasNeededPermission = userPermissions.includes(neededPerm);

  if (!hasNeededPermission) {
    // If the user does NOT have the permission, redirect or show unauthorized
    return <Navigate to="/no-access" />;
  }

  // 4. If all checks pass, render the child
  return children;
};

export default PrivateRoute;
