import React from 'react';
import { RouteNode } from '../../@types';

const EmployeeCompensation = React.lazy(
  () => import('../../pages/employeeCompensation/List')
);
const EmployeeCompensationDetail = React.lazy(
  () => import('../../pages/employeeCompensation/Details')
);
const PayrollRunList = React.lazy(
  () => import('../../pages/payrollRun/PayrollRun')
);
const PayrollRunRun = React.lazy(
  () => import('../../pages/payrollRun/FormRunPayroll/FormRunPayroll')
);
const PayrollRunDetail = React.lazy(
  () => import('../../pages/payrollRun/DetailRunPayroll/DetailRunPayroll')
);
const DetailPayrollRunPayment = React.lazy(
  () => import('../../pages/payrollRun/DetailRunPayroll/DetailPayment')
);

const PaymentCreate = React.lazy(
  () => import('../../pages/payrollRun/DetailRunPayroll/PaymentCreate')
);

const OneTimePaymentList = React.lazy(
  () => import('../../pages/payrollRun/OneTimePayment/OneTimePayment')
);

const OneTimePaymentCreate = React.lazy(
  () => import('../../pages/payrollRun/OneTimePayment/Create')
);

const payrollRun: RouteNode[] = [
  {
    label: 'Run Payroll',
    path: 'payroll-run',
    child: null,
    component: PayrollRunList,
    requiredPermissions: 'VIEW',
  },
  {
    label: '',
    path: 'payroll-run/regular/run/:id',
    child: null,
    component: PayrollRunRun,
    requiredPermissions: 'CREATE',
  },
  {
    label: '',
    path: 'payroll-run/one-time-payment',
    child: null,
    component: OneTimePaymentList,
    requiredPermissions: 'VIEW',
  },
  {
    label: '',
    path: 'payroll-run/one-time-payment/create',
    child: null,
    component: OneTimePaymentCreate,
    requiredPermissions: 'CREATE',
  },
  {
    label: '',
    path: 'payroll-run/regular/detail/:id',
    child: null,
    component: PayrollRunDetail,
    requiredPermissions: 'VIEW',
  },
  {
    label: '',
    path: 'payroll-run/regular/detail/:id/payment-detail/:employeeId',
    child: null,
    component: DetailPayrollRunPayment,
    requiredPermissions: 'VIEW',
  },
  // DEPRECATED
  // {
  //   label: '',
  //   path: 'compensation/detail/:id',
  //   component: EmployeeCompensationDetail,
  //   child: null,
  //   requiredPermissions: 'VIEW',
  // },
  {
    label: '',
    path: 'payroll-run/regular/detail/:id/payment-detail/:employeeId/create',
    child: null,
    component: PaymentCreate,
    requiredPermissions: 'CREATE',
  },
  {
    label: '',
    path: 'payroll-run/off-cycle/run/:id',
    child: null,
    component: PayrollRunRun,
    requiredPermissions: 'CREATE',
  },
  {
    label: '',
    path: 'payroll-run/off-cycle/detail/:id',
    child: null,
    component: PayrollRunDetail,
    requiredPermissions: 'VIEW',
  },
  {
    label: '',
    path: 'payroll-run/off-cycle/detail/:id/payment-detail/:employeeId',
    child: null,
    component: DetailPayrollRunPayment,
    requiredPermissions: 'VIEW',
  },
  {
    label: '',
    path: 'payroll-run/off-cycle/detail/:id/payment-detail/:employeeId/create',
    child: null,
    component: PaymentCreate,
    requiredPermissions: 'CREATE',
  },
];

export default payrollRun;
