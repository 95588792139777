import { lazy } from 'react';
import { RouteNode } from '../../../@types';

const List = lazy(() => import('../../../pages/claimRequest/List'));
const Detail = lazy(() => import('../../../pages/claimRequest/Detail'));

export const claimRequest: RouteNode[] = [
  {
    label: 'Claim Request',
    child: null,
    path: 'claim-request',
    component: List,
    requiredPermissions: 'VIEW',
  },
  {
    label: '',
    child: null,
    path: 'claim-request/detail/:id',
    component: Detail,
    requiredPermissions: 'VIEW',
  },
];
