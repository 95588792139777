import React from 'react';
import { RouteNode } from '../../@types';

const Create = React.lazy(() => import('../../pages/employee/create/Files'));

const Corrections = React.lazy(
  () => import('../../pages/employee/correction/Files')
);

const file: RouteNode[] = [
  {
    label: '',
    path: 'employee/detail/:id/files/create',
    component: Create,
    child: null,
    requiredPermissions: 'CREATE',
  },
  {
    label: '',
    path: 'employee/detail/:id/files/update/:ccid',
    component: Corrections,
    child: null,
    requiredPermissions: 'UPDATE',
  },
  {
    label: '',
    path: 'employee/detail/:id/files/correction/:ccid',
    component: Corrections,
    child: null,
    requiredPermissions: 'CORRECTION',
  },
];

export default file;
