import { Box, useMediaQuery } from '@mui/material';
import React, { useState } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { AuthProvider } from 'react-oidc-context';
import { RouteNode } from './@types/RouteProps';
import { Sidebar } from './components/organism';
import Header from './components/template/header/Header';
import {
  Demo,
  ForgotPassword,
  NewPassword,
  Thankyou,
  LatestPayslip,
  ESSEmployeeProfile,
  DeleteAccount,
  ListPayslip,
  ListApprovedTimeOff,
} from './pages';
import Generate from './pages/reportGeneration/generate';
import CompilePaySlip from './pages/reportGeneration/compilePayslip';
import ErrorBoundary from './pages/ErrorBoundry/ErrorBoundry';
import RoutePath from './route';
import Settings from './route/settings';
import PrivateRoute from './route/PrivateRoute';
import PublicRoute from './route/PublicRoute';
import { getCookie } from './utils/cookies';
import BlockLayout from './components/template/blockLayout/BlockLayout';
import { ProgressOverlay } from './components/molecule/progressOverlay';
import { useAppSelector } from './store';
import PreviewLogo from './pages/previewLogo/PreviewLogo';
import { hasSettingUrl, isEssRoute } from './utils/sideBarUtil';
import DetailApprovedTimeOff from './pages/ESSEmployee/DetailApprovedTimeOff';
import NoAccess from './pages/NoAccess';
import ESSPrivateRoute from './route/ESSPrivateRoute';

const Login = React.lazy(() => import('./pages/login/Login'));

const Otp = React.lazy(() => import('./pages/otp/Otp'));

const RouteTreeList = (nodes: RouteNode[], path: string = ''): RouteNode[] => {
  let leafNodes: RouteNode[] = [];

  nodes.forEach(node => {
    if (!node.child) {
      leafNodes.push({
        ...node,
        path: path ? `${path}/${node.path}` : `/${node.path}`,
      });
    } else {
      leafNodes = [
        ...leafNodes,
        ...RouteTreeList(node.child, `${path}/${node.path}`),
      ];
    }
  });

  return leafNodes;
};

function App() {
  const [isTokenFound, setTokenFound] = useState(false);
  const routePath = RouteTreeList(RoutePath, '');
  const settingPath = RouteTreeList(Settings, '');
  const navigate = useNavigate();
  const location = useLocation();
  const matches = useMediaQuery('(max-width:1023px)');
  const matchLayoutBlocking = useMediaQuery('(max-width:319px)');

  const handleOnOtpIdle = () => {
    const remainingTime = getRemainingOtpTime();
    if (remainingTime === 0 && location.pathname === '/otp') {
      navigate('/login');
    }
  };

  const handleOnIdle = () => {
    const remainingTime = getRemainingTime();
    if (remainingTime === 0 && location.pathname !== '/otp') {
      navigate('/login');
    }
  };

  const { getRemainingTime: getRemainingOtpTime } = useIdleTimer({
    timeout: 1000 * 60 * 5,
    onIdle: handleOnOtpIdle,
    debounce: 500,
  });

  const { getRemainingTime } = useIdleTimer({
    timeout: 1000 * 60 * 45,
    onIdle: handleOnIdle,
    debounce: 500,
  });
  const { isLoading } = useAppSelector(state => state.utils);

  const { clientId, clientSecret, authority, scope } = useAppSelector(
    state => state.auth
  );

  const oidcConfig = {
    client_id: clientId,
    authority,
    client_secret: clientSecret,
    redirect_uri: `${window.location.protocol}//${window.location.host}/login`,
    response_type: 'code',
    scope,
  };

  return (
    <AuthProvider {...oidcConfig} key={oidcConfig.client_id}>
      <>
        {isLoading ? <ProgressOverlay /> : null}
        {isTokenFound && Notification}
        {/* {!isTokenFound && Need} */}
        {getCookie('app__refresh_token') &&
          !matchLayoutBlocking &&
          !location.pathname.includes('/preview-profile-url') && <Header />}
        {matchLayoutBlocking ? (
          <BlockLayout />
        ) : (
          <Box
            sx={{
              display: 'flex',
              justifyContent: matches ? 'center' : 'normal',
              alignItems: matches ? 'center' : 'flex-start',
            }}
          >
            {/* default sidebar */}
            {getCookie('app__refresh_token') &&
              !location.pathname.includes('/custom-field') &&
              !location.pathname.includes('/security') &&
              !location.pathname.includes('/workflows') &&
              location.pathname.split('/')?.[1] !== 'ess' &&
              !location.pathname.includes('/user-interface') &&
              !location.pathname.includes('/preview-profile-url') &&
              !location.pathname.includes(
                '/payroll/payroll-run/regular/detail/download'
              ) &&
              !location.pathname.includes('/generation') && (
                <Sidebar data={RoutePath} />
              )}

            {/* settings sidebar */}
            {getCookie('app__refresh_token') &&
              // (location.pathname.includes('/custom-field') ||
              //   location.pathname.includes('/user-interface') ||
              //   location.pathname.includes('/security') ||
              //   location.pathname.includes('/workflows')) &&
              // !location.pathname.includes('/ess') &&
              hasSettingUrl() &&
              !location.pathname.includes('/preview-profile-url') &&
              Boolean(!isEssRoute) && <Sidebar data={Settings} />}
            <Routes>
              <Route
                key="/docs/delete-your-account"
                path="/docs/delete-your-account"
                element={
                  <React.Suspense fallback={<div>Loading</div>}>
                    <PublicRoute>
                      <DeleteAccount />
                    </PublicRoute>
                  </React.Suspense>
                }
              />
              <Route
                key="/preview-profile-url"
                path="/preview-profile-url"
                element={
                  <React.Suspense fallback={<div>Loading</div>}>
                    <ESSPrivateRoute>
                      <PreviewLogo />
                    </ESSPrivateRoute>
                  </React.Suspense>
                }
              />
              <Route
                key="/report/standard-report/generation"
                path="/report/standard-report/generation"
                element={
                  <React.Suspense fallback={<div>Loading</div>}>
                    {/* <PublicRoute> */}
                    <Generate />
                    {/* </PublicRoute> */}
                  </React.Suspense>
                }
              />
              <Route
                key="/payroll/payroll-run/regular/detail/download"
                path="/payroll/payroll-run/regular/detail/download"
                element={
                  <React.Suspense fallback={<div>Loading</div>}>
                    {/* <PublicRoute> */}
                    <CompilePaySlip />
                    {/* </PublicRoute> */}
                  </React.Suspense>
                }
              />

              <Route
                key="/login"
                path="/login"
                element={
                  <React.Suspense fallback={<div>Loading</div>}>
                    <PublicRoute>
                      <Login />
                    </PublicRoute>
                  </React.Suspense>
                }
              />
              <Route
                key="/otp"
                path="/otp"
                element={
                  <React.Suspense fallback={<div>Loading</div>}>
                    <PublicRoute>
                      <Otp />
                    </PublicRoute>
                  </React.Suspense>
                }
              />
              <Route
                key="/forgotPassword"
                path="/forgot-password"
                element={
                  <React.Suspense fallback={<div>Loading</div>}>
                    <PublicRoute>
                      <ForgotPassword />
                    </PublicRoute>
                  </React.Suspense>
                }
              />
              <Route
                key="/newPassword"
                path="/new-password/:token"
                element={
                  <React.Suspense fallback={<div>Loading</div>}>
                    <PublicRoute>
                      <NewPassword />
                    </PublicRoute>
                  </React.Suspense>
                }
              />
              <Route
                key="/demo"
                path="/request-demo"
                element={
                  <React.Suspense fallback={<div>Loading</div>}>
                    <PublicRoute>
                      <Demo />
                    </PublicRoute>
                  </React.Suspense>
                }
              />
              <Route
                key="/thankyou"
                path="/thankyou"
                element={
                  <React.Suspense fallback={<div>Loading</div>}>
                    <PublicRoute>
                      <Thankyou />
                    </PublicRoute>
                  </React.Suspense>
                }
              />
              <Route
                key="/ess/employee"
                path="/ess/employee"
                element={
                  <React.Suspense fallback={<div>Loading</div>}>
                    <ErrorBoundary>
                      <ESSPrivateRoute>
                        <LatestPayslip />
                      </ESSPrivateRoute>
                    </ErrorBoundary>
                  </React.Suspense>
                }
              />
              <Route
                key="/ess/employee/payslips"
                path="/ess/employee/payslips"
                element={
                  <React.Suspense fallback={<div>Loading</div>}>
                    <ErrorBoundary>
                      <ESSPrivateRoute>
                        <ListPayslip />
                      </ESSPrivateRoute>
                    </ErrorBoundary>
                  </React.Suspense>
                }
              />
              <Route
                key="/ess/employee/details"
                path="/ess/employee/details"
                element={
                  <React.Suspense fallback={<div>Loading</div>}>
                    <ErrorBoundary>
                      <ESSPrivateRoute>
                        <ESSEmployeeProfile />
                      </ESSPrivateRoute>
                    </ErrorBoundary>
                  </React.Suspense>
                }
              />
              <Route
                key="/ess/employee/approved-time-off"
                path="/ess/employee/approved-time-off"
                element={
                  <React.Suspense fallback={<div>Loading</div>}>
                    <ErrorBoundary>
                      <ESSPrivateRoute>
                        <ListApprovedTimeOff />
                      </ESSPrivateRoute>
                    </ErrorBoundary>
                  </React.Suspense>
                }
              />
              <Route
                key="/ess/employee/approved-time-off/detail"
                path="/ess/employee/approved-time-off/detail/:id"
                element={
                  <React.Suspense fallback={<div>Loading</div>}>
                    <ErrorBoundary>
                      <ESSPrivateRoute>
                        <DetailApprovedTimeOff />
                      </ESSPrivateRoute>
                    </ErrorBoundary>
                  </React.Suspense>
                }
              />
              <Route
                key="/no-access"
                path="/no-access"
                element={
                  <React.Suspense fallback={<div>Loading</div>}>
                    <ErrorBoundary>
                      <NoAccess />
                    </ErrorBoundary>
                  </React.Suspense>
                }
              />
              {routePath.map((node: RouteNode) => {
                const { component: Component } = node;
                return (
                  <Route
                    key={node.path}
                    path={node.path}
                    element={
                      <React.Suspense fallback={<div>Loading</div>}>
                        <ErrorBoundary>
                          {Component && (
                            <PrivateRoute node={node}>
                              <Component />
                            </PrivateRoute>
                          )}
                        </ErrorBoundary>
                      </React.Suspense>
                    }
                  />
                );
              })}
              {settingPath.map((node: RouteNode) => {
                const { component: Component } = node;
                return (
                  <Route
                    key={node.path}
                    path={node.path}
                    element={
                      <React.Suspense fallback={<div>Loading</div>}>
                        <ErrorBoundary>
                          {Component && (
                            <PrivateRoute node={node}>
                              <Component />
                            </PrivateRoute>
                          )}
                        </ErrorBoundary>
                      </React.Suspense>
                    }
                  />
                );
              })}
              <Route path="*" element={<div>Not Found!</div>} />
            </Routes>
          </Box>
        )}
      </>
    </AuthProvider>
  );
}

export default App;
