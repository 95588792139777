import React from 'react';
import { RouteNode } from '../../@types';

const TaxLocationCreate = React.lazy(
  () => import('../../pages/legalEntity/taxLocation/Create')
);
const TaxLocationUpdate = React.lazy(
  () => import('../../pages/legalEntity/taxLocation/Update')
);
const TaxLocationCorrection = React.lazy(
  () => import('../../pages/legalEntity/taxLocation/Correction')
);

const LegalEntityTaxLocation: RouteNode[] = [
  {
    label: '',
    path: 'legal-entity/detail/:id/taxlocations/create',
    component: TaxLocationCreate,
    child: null,
    requiredPermissions: 'CREATE',
  },
  {
    label: '',
    path: 'legal-entity/detail/:id/taxlocations/update/:groupId',
    component: TaxLocationUpdate,
    child: null,
    requiredPermissions: 'UPDATE',
  },
  {
    label: '',
    path: 'legal-entity/detail/:id/taxlocations/correction/:groupId',
    component: TaxLocationCorrection,
    child: null,
    requiredPermissions: 'CORRECTION',
  },
];

export default LegalEntityTaxLocation;
