import React from 'react';
import { RouteNode } from '../../../@types';

const List = React.lazy(() => import('../../../pages/timeOffRequest/List'));
const Detail = React.lazy(() => import('../../../pages/timeOffRequest/Detail'));

export const timeOffRequest: RouteNode[] = [
  {
    label: 'Request',
    child: null,
    path: 'request',
    component: List,
    requiredPermissions: 'VIEW',
  },
  {
    label: '',
    child: null,
    path: 'request/detail/:id',
    component: Detail,
    requiredPermissions: 'VIEW',
  },
];
