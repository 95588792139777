import React from 'react';
import { RouteNode } from '../../../@types';

const List = React.lazy(() => import('../../../pages/timeOffItem/List'));
const Create = React.lazy(() => import('../../../pages/timeOffItem/Create'));
const Detail = React.lazy(() => import('../../../pages/timeOffItem/Detail'));

export const timeOffItem: RouteNode[] = [
  {
    label: 'Time Off Item',
    child: null,
    path: 'time-off-items',
    component: List,
    requiredPermissions: 'VIEW',
  },
  {
    label: '',
    child: null,
    path: 'time-off-items/create',
    component: Create,
    requiredPermissions: 'CREATE',
  },
  {
    label: '',
    path: 'time-off-items/detail/:id',
    component: Detail,
    child: null,
    requiredPermissions: 'VIEW',
  },
];
