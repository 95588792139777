import React from 'react';
import { RouteNode } from '../../@types';

const List = React.lazy(() => import('../../pages/chartOfAccounts/List'));
const Create = React.lazy(() => import('../../pages/chartOfAccounts/Create'));
const Detail = React.lazy(() => import('../../pages/chartOfAccounts/Detail'));
const Update = React.lazy(
  () => import('../../pages/taxLocation/TaxLocationUpdate')
);
const Correction = React.lazy(
  () => import('../../pages/costCenter/Correction')
);

const chartOfAccount: RouteNode[] = [
  {
    label: 'Chart of Accounts',
    path: 'chart-of-accounts',
    component: List,
    child: null,
    requiredPermissions: 'VIEW',
  },
  {
    label: '',
    path: 'chart-of-accounts/create',
    component: Create,
    child: null,
    requiredPermissions: 'CREATE',
  },
  {
    label: '',
    path: 'chart-of-accounts/detail/:id',
    component: Detail,
    child: null,
    requiredPermissions: 'VIEW',
  },
  {
    label: '',
    path: 'chart-of-accounts/update/:id',
    component: Update,
    child: null,
    requiredPermissions: 'UPDATE',
  },
];

export default chartOfAccount;
