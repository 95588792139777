const tableListKey: { [key: string]: any } = {
  name: 'Name',
  code: 'Code',
};

const listVariant = [
  'SETUP',
  'SPECIFIC DATA ACCESS',
  'SPECIFIC FUNCTION ACCESS',
];
const stepperRunAccessControl = [
  'SETUP',
  'SPECIFIC DATA ACCESS',
  'SPECIFIC FUNCTION ACCESS',
];

const detailListKey: { [key: string]: any } = {
  name: 'Name',
  code: 'Code',
};

export default {
  tableListKey,
  listVariant,
  stepperRunAccessControl,
  detailListKey,
};

export const sidebarDictionary: { [key: string]: Array<string> } = {
  Home: ['home'],
  Employee: ['employee'],
  Payroll: [
    'run_payroll',
    'pay_group',
    'pay_item',
    'accumulator',
    'bank_file',
    'exchange_rate',
    'formula',
    'payslip_template',
    'statutory',
    'value_rounding',
  ],
  'Run Payroll': ['run_payroll'],
  'Setup payroll': ['pay_group', 'pay_item'],
  'Setup time': [
    'holiday',
    'job_site',
    'overtime',
    'shift',
    'schedule',
    'time_off',
  ],
  Claim: ['claim_type', 'claim'],
  'Configuration claim': ['claim_type'],
  'Setup claim': ['claim'],
  'Claim Type': ['claim_type'],
  'Claim Request': ['claim_request'],
  'Claim Process': ['claim_process'],
  'Pay Group': ['pay_group'],
  'Pay Item': ['pay_item'],
  Accumulator: ['accumulator'],
  'Bank File': ['bank_file'],
  'Exchange Rate': ['exchange_rate'],
  Formula: ['formula'],
  'Payslip Template': ['payslip_template'],
  Statutory: ['statutory'],
  'Value Rounding': ['value_rounding'],
  Time: [
    'analytics',
    'work_schedule',
    'attendance_process',
    'daily_attendance',
    'overtime_request',
    'time_off_request',
    'holiday',
    'job_site',
    'overtime',
    'shift',
    'schedule',
    'time_off',
    'attendance_machine',
    'attendance_status',
    'overtime_item',
    'policy',
    'time_event',
    'time_item',
    'time_off_item',
  ],
  Analytics: ['analytics'],
  'Work Schedule': ['work_schedule'],
  Attendance: ['attendance_process', 'daily_attendance'],
  'Attendance Process': ['attendance_process'],
  'Daily Attendance': ['daily_attendance'],
  Request: ['overtime_request'],
  Holiday: ['holiday'],
  'Job Site': ['job_site'],
  Shift: ['shift'],
  Schedule: ['schedule'],
  'Configuration payroll': [
    'accumulator',
    'bank_file',
    'exchange_rate',
    'formula',
    'payslip_template',
    'statutory',
    'value_rounding',
  ],
  'Configuration time': [
    'attendance_machine',
    'attendance_status',
    'overtime_item',
    'policy',
    'time_item',
    'time_off_item',
  ],
  'Attendance Machine': ['attendance_machine'],
  'Attendance Status': ['attendance_status'],
  'Overtime Item': ['overtime_item'],
  Policy: ['policy'],
  'Time Event': ['time_event'],
  'Time Item': ['time_item'],
  'Time Off Item': ['time_off_item'],
  Overtime: ['overtime_request'],
  'Time Off': ['time_off_request'],
  Organization: ['legal_entity', 'job_grade'],
  'Legal Entity': ['legal_entity'],
  'Organization Structure': ['organization_structure'],
  'Job Grade': ['job_grade'],
  'Job Level': ['job_level'],
  'Job Position': ['job_position'],
  'Organization Unit': ['organization_unit'],
  'Work Location': ['work_location'],
  File: ['file'],
  'Master Data': ['bank', 'cost_center', 'employment_type', 'tax_location'],
  Bank: ['bank'],
  'Cost Center': ['cost_center'],
  'Employment Type': ['employment_type'],
  'Tax Location': ['tax_location'],
  Accounting: ['chart_of_account'],
  'Chart of Accounts': ['chart_of_account'],
  Report: ['standard_report'],
  'Standard Report': ['standard_report'],
  Import: ['import'],
  Export: ['export'],
  'Custom Fields': ['custom_field'],
  'User Interface': ['user_interface'],
  Security: ['user_access', 'access_control', 'ess_access_control'],
  'User Access': ['user_access'],
  'Access Control': ['access_control'],
  'ESS Access Control': ['ess_access_control'],
};
