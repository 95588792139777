import React from 'react';
import { RouteNode } from '../../@types';

const JobPositionList = React.lazy(
  () => import('../../pages/jobPosition/JobPositionList')
);
const JobPositinDetail = React.lazy(
  () => import('../../pages/jobPosition/JobPositionDetails')
);
const JobPositionCreate = React.lazy(
  () => import('../../pages/jobPosition/JobPositionCreate')
);
const JobPositionUpdate = React.lazy(
  () => import('../../pages/jobPosition/JobPositionUpdate')
);
const JobPositionCorrection = React.lazy(
  () => import('../../pages/jobPosition/JobPositionCorrection')
);

const jobPosition: RouteNode[] = [
  {
    label: 'Job Position',
    path: 'job-position',
    component: JobPositionList,
    child: null,
    requiredPermissions: 'VIEW',
  },
  {
    label: '',
    path: 'job-position/create',
    component: JobPositionCreate,
    child: null,
    requiredPermissions: 'CREATE',
  },
  {
    label: '',
    path: 'job-position/detail/:id',
    component: JobPositinDetail,
    child: null,
    requiredPermissions: 'VIEW',
  },
  {
    label: '',
    path: 'job-position/update/:id',
    component: JobPositionUpdate,
    child: null,
    requiredPermissions: 'UPDATE',
  },
  {
    label: '',
    path: 'job-position/correction/:id',
    component: JobPositionCorrection,
    child: null,
    requiredPermissions: 'CORRECTION',
  },
];

export default jobPosition;
