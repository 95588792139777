import { useNavigate } from 'react-router-dom';
import { Container, Box, Typography, useTheme } from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { Button } from '../components/atom';

const NoAccess = () => {
  const { palette } = useTheme();
  const navigate = useNavigate();

  return (
    <Container component="main" maxWidth="sm">
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          minHeight: '80vh',
        }}
      >
        <Box
          sx={{
            p: 4,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '1rem',
          }}
        >
          <LockOutlinedIcon sx={{ fontSize: 80, color: palette.gray[90] }} />
          <Typography variant="h4" color={palette.gray[100]} fontWeight="700">
            Access Denied
          </Typography>
          <Typography variant="body16Bold">
            You don't have permission to access this page.
          </Typography>
          <Typography
            variant="body2"
            align="center"
            color="text.secondary"
            sx={{ mb: 4 }}
          >
            Please contact your administrator if you believe this is an error.
          </Typography>
          <Button onClick={() => navigate('/')} variant="link">
            Return to Home
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

export default NoAccess;
