import { Collapse, List } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { NavMenu } from '../../atom';
import { RouteProps, RouteNode } from '../../../@types/RouteProps';
import { useAppDispatch, useAppSelector } from '../../../store';
import { closeSideBar } from '../../../store/feature/responsiveSlice';
import { sidebarDictionary } from '../../../constants/accessControl';

const TreeList: React.FC<RouteProps> = ({ data, sx }) => {
  const { dataAccess } = useAppSelector(state => state.userAccess);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const [expanded, setExpanded] = useState<string[]>([]);

  const checkAccess = (label: string) => {
    let viewable = false;
    for (let index = 0; index < sidebarDictionary[label]?.length; index += 1) {
      const element = sidebarDictionary[label][index];
      if (dataAccess[element]?.indexOf('VIEW') > -1) {
        viewable = true;
        break;
      }
    }
    return viewable;
  };

  // Expand according to current URL on page load or location change
  useEffect(() => {
    const pathSegments = location.pathname
      .replace(/^\//, '') // remove leading slash
      .split('/')
      .filter(Boolean); // remove empty segments

    const expansionsToSet: string[] = [];
    for (let i = 0; i < pathSegments.length - 1; i += 1) {
      const segmentPath = pathSegments.slice(0, i + 1).join('/');
      expansionsToSet.push(segmentPath);
    }

    setExpanded(expansionsToSet);
  }, [location.pathname]);

  const handleClick = (fullPath: string, isParent: boolean, depth: number) => {
    if (!isParent) {
      // Leaf node: If it's top-level (depth=0), close other menus
      if (depth === 0) {
        // Close all expansions when navigating to a top-level leaf menu
        setExpanded([]);
      }
      navigate(fullPath === '/' ? '/' : `/${fullPath}`);
      dispatch(closeSideBar());
    } else {
      // Parent node: Just toggle its expansion, do not close other top-level menus
      // because user wants no top-level closing unless it's a leaf navigation.
      setExpanded(prev => {
        if (prev.includes(fullPath)) {
          return prev.filter(item => item !== fullPath);
        }
        return [...prev, fullPath];
      });
    }
  };

  const checkIsActive = (fullPath: string) =>
    location.pathname.startsWith(`/${fullPath}`);

  const formatLabel = (label: string, parentPaths: string[]) => {
    const keys = ['Setup', 'Configuration'];
    if (keys.includes(label) && parentPaths.includes('payroll')) {
      return `${label} payroll`;
    }
    if (keys.includes(label) && parentPaths.includes('time')) {
      return `${label} time`;
    }
    if (keys.includes(label) && parentPaths.includes('claim')) {
      return `${label} claim`;
    }
    return label;
  };

  const renderList = (
    nodes: RouteNode[],
    depth: number,
    parentPaths: string[]
  ) => (
    <List
      component="nav"
      sx={{
        padding: 0,
        display: 'flex',
        flexDirection: 'column',
        gap: '4px',
        ...sx,
      }}
    >
      {nodes
        .filter(node => node.label !== 'child')
        .map(node => {
          const { icon: ComponentIcon } = node;
          const fullPath = [...parentPaths, node.path]
            .filter(Boolean)
            .join('/');

          return (
            <React.Fragment key={fullPath}>
              {node.label &&
                checkAccess(formatLabel(node.label, parentPaths)) && (
                  <NavMenu
                    onClick={() =>
                      handleClick(fullPath, node.child !== null, depth)
                    }
                    path={fullPath}
                    label={node.label}
                    isActive={
                      node.path === '/' && location.pathname === '/'
                        ? true
                        : checkIsActive(fullPath)
                    }
                    depth={depth}
                    icon={ComponentIcon ? <ComponentIcon /> : null}
                    isParent={node.child !== null}
                    isExpended={expanded.includes(fullPath)}
                  />
                )}
              {node.child && (
                <Collapse
                  in={expanded.includes(fullPath)}
                  timeout="auto"
                  unmountOnExit
                >
                  {renderList(node.child, depth + 1, [
                    ...parentPaths,
                    node.path,
                  ])}
                </Collapse>
              )}
            </React.Fragment>
          );
        })}
    </List>
  );

  return renderList(data, 0, []);
};

export default TreeList;
