import { Navigate } from 'react-router-dom';
import { getCookie } from '../utils/cookies';

interface ESSPrivateRouteProps {
  children?: any;
}

const ESSPrivateRoute = (props: ESSPrivateRouteProps) => {
  const { children } = props;
  return !getCookie('app__refresh_token') ? <Navigate to="/login" /> : children;
};

export default ESSPrivateRoute;
