import { lazy } from 'react';
import { RouteNode } from '../../../@types';

const List = lazy(() => import('../../../pages/timeAnalytics/List'));

export const AnalyticRoute: RouteNode[] = [
  {
    label: 'Analytics',
    child: null,
    path: 'analytics',
    component: List,
    requiredPermissions: 'VIEW',
  },
];
