import React from 'react';
import { RouteNode } from '../../@types';

const PayItemList = React.lazy(() => import('../../pages/payItem/List'));
const PayItemCreate = React.lazy(
  () => import('../../pages/payItem/PayItemCreate')
);
const PayItemDetails = React.lazy(() => import('../../pages/payItem/Details'));
const PayGroupList = React.lazy(() => import('../../pages/payGroup/List'));
const PayItemUpdate = React.lazy(() => import('../../pages/payItem/Update'));
const PayItemCorrection = React.lazy(
  () => import('../../pages/payItem/Correction')
);
const PayGroupDetails = React.lazy(
  () => import('../../pages/payGroup/Details')
);
const PayGroupCreate = React.lazy(
  () => import('../../pages/payGroup/CreatePayGroup')
);
const PayGroupEdit = React.lazy(
  () => import('../../pages/payGroup/EditPayGroup')
);

const payGroup: RouteNode[] = [
  {
    label: 'Setup',
    path: 'setup',
    component: null,
    child: [
      {
        label: 'Pay Group',
        path: 'setup-payroll',
        child: null,
        component: PayGroupList,
        requiredPermissions: 'VIEW',
      },
      {
        label: '',
        path: 'setup-payroll/detail/:id',
        child: null,
        component: PayGroupDetails,
        requiredPermissions: 'VIEW',
      },
      {
        label: '',
        path: 'setup-payroll/create',
        child: null,
        component: PayGroupCreate,
        requiredPermissions: 'CREATE',
      },
      {
        label: '',
        path: 'setup-payroll/edit/:id',
        child: null,
        component: PayGroupEdit,
        requiredPermissions: 'UPDATE',
      },
      {
        label: 'Pay Item',
        path: 'pay-item',
        child: null,
        component: PayItemList,
        requiredPermissions: 'VIEW',
      },
      {
        label: '',
        path: 'pay-item/update/:id',
        child: null,
        component: PayItemUpdate,
        requiredPermissions: 'UPDATE',
      },
      {
        label: '',
        path: 'pay-item/correction/:id',
        child: null,
        component: PayItemCorrection,
        requiredPermissions: 'CORRECTION',
      },
      {
        label: '',
        path: 'pay-item/create',
        child: null,
        component: PayItemCreate,
        requiredPermissions: 'CREATE',
      },
      {
        label: '',
        path: 'pay-item/detail/:id',
        child: null,
        component: PayItemDetails,
        requiredPermissions: 'VIEW',
      },
    ],
  },
];

export default payGroup;
