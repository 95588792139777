import { lazy } from 'react';
import { RouteNode } from '../../../@types';

const List = lazy(() => import('../../../pages/claimProcess/List'));
const Detail = lazy(() => import('../../../pages/claimProcess/Detail'));
const Create = lazy(() => import('../../../pages/claimProcess/Create'));

export const claimProcess: RouteNode[] = [
  {
    label: 'Claim Process',
    child: null,
    path: 'claim-process',
    component: List,
    requiredPermissions: 'VIEW',
  },
  {
    label: '',
    child: null,
    path: 'claim-process/create',
    component: Create,
    requiredPermissions: 'CREATE',
  },
  {
    label: '',
    child: null,
    path: 'claim-process/detail/:id',
    component: Detail,
    requiredPermissions: 'VIEW',
  },
];
