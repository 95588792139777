import React from 'react';
import { RouteNode } from '../../../@types';

const AccumulatorList = React.lazy(
  () => import('../../../pages/accumulator/AccumulatorList')
);
const AccumulatorForm = React.lazy(
  () => import('../../../pages/accumulator/AccumulatorForm')
);
const AccumulatorDetail = React.lazy(
  () => import('../../../pages/accumulator/AccumulatorDetail')
);
const AccumulatorUpdate = React.lazy(
  () => import('../../../pages/accumulator/AccumulatorUpdate')
);
const AccumulatorCorrection = React.lazy(
  () => import('../../../pages/accumulator/AccumulatorCorrection')
);

const accumulatorRoutes: RouteNode[] = [
  {
    label: 'Accumulator',
    path: 'accumulator',
    child: null,
    component: AccumulatorList,
    requiredPermissions: 'VIEW',
  },
  {
    label: '',
    path: 'accumulator/create',
    child: null,
    component: AccumulatorForm,
    requiredPermissions: 'CREATE',
  },
  {
    label: '',
    path: 'accumulator/detail/:id',
    child: null,
    component: AccumulatorDetail,
    requiredPermissions: 'VIEW',
  },
  {
    label: '',
    path: 'accumulator/update/:id',
    child: null,
    component: AccumulatorUpdate,
    requiredPermissions: 'UPDATE',
  },
  {
    label: '',
    path: 'accumulator/correction/:id',
    child: null,
    component: AccumulatorCorrection,
    requiredPermissions: 'CORRECTION',
  },
];

export default accumulatorRoutes;
