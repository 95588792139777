import React from 'react';
import { RouteNode } from '../../@types';

const OrganizationUnitList = React.lazy(
  () => import('../../pages/organizationUnit/OrganizationUnitList')
);
const OrganizationUnitForm = React.lazy(
  () => import('../../pages/organizationUnit/OrganizationunitForm')
);
const OrganizationUnitDetails = React.lazy(
  () => import('../../pages/organizationUnit/OrganizationUnitDetails')
);
const OrganizationUnitUpdate = React.lazy(
  () => import('../../pages/organizationUnit/OrganizationUnitUpdate')
);
const OrganizationUnitCorrection = React.lazy(
  () => import('../../pages/organizationUnit/OrganizationUnitCorrection')
);

const organizationUnit: RouteNode[] = [
  {
    label: 'Organization Unit',
    path: 'organization-unit',
    component: OrganizationUnitList,
    child: null,
    requiredPermissions: 'VIEW',
  },
  {
    label: '',
    path: 'organization-unit/create',
    component: OrganizationUnitForm,
    child: null,
    requiredPermissions: 'CREATE',
  },
  {
    label: '',
    path: 'organization-unit/detail/:id',
    component: OrganizationUnitDetails,
    child: null,
    requiredPermissions: 'VIEW',
  },
  {
    label: '',
    path: 'organization-unit/update/:id',
    component: OrganizationUnitUpdate,
    child: null,
    requiredPermissions: 'UPDATE',
  },
  {
    label: '',
    path: 'organization-unit/correction/:id',
    component: OrganizationUnitCorrection,
    child: null,
    requiredPermissions: 'CORRECTION',
  },
];

export default organizationUnit;
