import React from 'react';
import { RouteNode } from '../../../@types';

const List = React.lazy(() => import('../../../pages/formula/List'));
const Detail = React.lazy(() => import('../../../pages/formula/Detail'));
const Create = React.lazy(() => import('../../../pages/formula/Create'));
const Update = React.lazy(() => import('../../../pages/formula/Update'));
const Correction = React.lazy(
  () => import('../../../pages/formula/Correction')
);
const formulaRoute: RouteNode[] = [
  {
    label: 'Formula',
    path: 'formula',
    child: null,
    component: List,
    requiredPermissions: 'VIEW',
  },
  {
    label: '',
    path: 'formula/detail/:id',
    child: null,
    component: Detail,
    requiredPermissions: 'VIEW',
  },
  {
    label: '',
    path: 'formula/create',
    child: null,
    component: Create,
    requiredPermissions: 'CREATE',
  },
  {
    label: '',
    path: 'formula/update/:id',
    child: null,
    component: Update,
    requiredPermissions: 'UPDATE',
  },
  {
    label: '',
    path: 'formula/correction/:id',
    child: null,
    component: Correction,
    requiredPermissions: 'CORRECTION',
  },
];

export default formulaRoute;
