import React from 'react';
import { RouteNode } from '../../../@types';

const List = React.lazy(() => import('../../../pages/claim/List'));
const Detail = React.lazy(() => import('../../../pages/claim/Detail'));

export const claim: RouteNode[] = [
  {
    label: 'Claim',
    child: null,
    path: 'claim',
    component: List,
    requiredPermissions: 'VIEW',
  },
  {
    label: '',
    path: 'claim/detail/:id',
    component: Detail,
    child: null,
    requiredPermissions: 'VIEW',
  },
];
