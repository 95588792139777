import React from 'react';
import { RouteNode } from '../../@types';

const ListRequest = React.lazy(
  () => import('../../pages/employee/requestTimeoffDetails/List')
);

const ListOvertime = React.lazy(
  () => import('../../pages/employee/requestOvertimeDetails/List')
);

const BalanceDetails = React.lazy(
  () => import('../../pages/employee/balance/BalanceDetails')
);

const ListLogHistory = React.lazy(
  () => import('../../pages/employee/balance/LogHistory/List')
);

const timeOff: RouteNode[] = [
  {
    label: '',
    path: 'employee/detail/:id/request-details',
    component: ListRequest,
    child: null,
    requiredPermissions: 'VIEW',
  },
  {
    label: '',
    path: 'employee/detail/:id/balance-details',
    component: BalanceDetails,
    child: null,
    requiredPermissions: 'VIEW',
  },
  {
    label: '',
    path: 'employee/detail/:id/balance/log-history/:balanceId',
    component: ListLogHistory,
    child: null,
    requiredPermissions: 'VIEW',
  },
  {
    label: '',
    path: 'employee/detail/:id/overtime-history-details',
    component: ListOvertime,
    child: null,
    requiredPermissions: 'VIEW',
  },
];

export default timeOff;
