export const capitalizeWords = (sentence: string) => {
  const words = sentence?.split('_');
  const capitalizedWords = words?.map(
    word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
  );

  return capitalizedWords?.join(' ');
};

export const toSnakeCase = (input: string) =>
  input
    // 1. Insert underscores between camelCase transitions: "myString" -> "my_String"
    .replace(/([a-z0-9])([A-Z])/g, '$1_$2')
    // 2. Replace any sequence of non-alphanumeric characters with an underscore
    .replace(/[^a-zA-Z0-9]+/g, '_')
    // 3. Convert all characters to lowercase
    .toLowerCase()
    // 4. Remove leading or trailing underscores
    .replace(/^_+|_+$/g, '');
