import React from 'react';
import { RouteNode } from '../../../@types';

const List = React.lazy(() => import('../../../pages/overtimeRequest/List'));
const Detail = React.lazy(
  () => import('../../../pages/overtimeRequest/Detail')
);

export const overtimeRequest: RouteNode[] = [
  {
    label: 'Request',
    child: null,
    path: 'overtime-request',
    component: List,
    requiredPermissions: 'VIEW',
  },
  {
    label: '',
    child: null,
    path: 'overtime-request/detail/:id',
    component: Detail,
    requiredPermissions: 'VIEW',
  },
];
