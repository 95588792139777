import { apiSlice } from '../../api/apiSlice';
import {
  PayloadReportAttendanceProcess,
  ITimeReportResponse,
  IPayloadReportOvertime,
  PayloadReportAttendance,
  PayloadTimeOffReport,
  PayloadTimeOffBalanceReport,
} from '../../../../@types/TMSReport';

type TRespGenerateReport = {
  id: string;
  reportId: string;
  status: string;
  createdAt: number;
  file: {
    fileName: string;
    fileUrl: string;
    mimeType: string;
    name: string;
  };
};

export const standardReportApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    postGenerate1721i: builder.mutation<any, any>({
      query: ({ payload }) => ({
        url: '/payroll/reports/1721-i-report/generate',
        method: 'POST',
        body: payload,
      }),
    }),
    postGeneratePayrollRegister: builder.mutation<
      TRespGenerateReport,
      { payload: string; payQueryId: string; paramsFilter: any }
    >({
      query: ({ payload, payQueryId, paramsFilter }) => ({
        url: `/payroll/report/payroll-register/${payQueryId}`,
        method: 'POST',
        body: payload,
        params: paramsFilter,
      }),
    }),
    postGenerateBPJSKesehatan: builder.mutation<any, any>({
      query: ({ payload }) => ({
        url: '/payroll/reports/bpjs-kesehatan/generate',
        method: 'POST',
        body: payload,
      }),
    }),
    postGenerateIncomeTaxCalculation: builder.mutation<any, any>({
      query: ({ payload }) => ({
        url: `/payroll/reports/income-tax-calculation`,
        method: 'POST',
        body: payload,
      }),
    }),
    getDataGenerate1721i: builder.query<any, any>({
      query: () => ({
        url: '/payroll/1721-i-report-configuration',
      }),
    }),
    generate1721a1Report: builder.mutation<any, any>({
      query: payload => ({
        url: '/payroll/reports/1721-a1-report/generate',
        method: 'POST',
        body: payload,
      }),
    }),
    generateBPJSKetenagakerjaan: builder.mutation<any, any>({
      query: payload => ({
        url: '/payroll/reports/bpjs-tk-baru/generate',
        method: 'POST',
        body: payload,
      }),
    }),
    generateBPJSKetenagakerjaanLanjutan: builder.mutation<any, any>({
      query: payload => ({
        url: '/payroll/reports/bpjs-tk-lanjutan/generate',
        method: 'POST',
        body: payload,
      }),
    }),
    generateCostCenterReport: builder.mutation<any, any>({
      query: payload => ({
        url: `/payroll/report/cost-center`,
        method: 'POST',
        body: payload,
      }),
    }),
    generateBupot2126: builder.mutation<any, any>({
      query: payload => ({
        url: '/payroll/reports/bupot/generate',
        method: 'POST',
        body: payload,
      }),
    }),
    // TMS REPORT //
    generateOvertimeReport: builder.mutation<
      ITimeReportResponse,
      IPayloadReportOvertime
    >({
      query: payload => ({
        url: '/time-management/reports/overtime/generate',
        method: 'POST',
        body: payload,
      }),
    }),
    generateAttendanceProcessReport: builder.mutation<
      ITimeReportResponse,
      PayloadReportAttendanceProcess
    >({
      query: payload => ({
        url: '/time-management/reports/attendance-process',
        method: 'POST',
        body: payload,
      }),
    }),
    generateAttendanceReport: builder.mutation<
      ITimeReportResponse,
      PayloadReportAttendance
    >({
      query: payload => ({
        url: '/time-management/reports/attendance',
        method: 'POST',
        body: payload,
      }),
    }),
    generateTimeOffReport: builder.mutation<
      ITimeReportResponse,
      PayloadTimeOffReport
    >({
      query: payload => ({
        url: '/time-management/reports/time-off-request',
        method: 'POST',
        body: payload,
      }),
    }),
    generateTimeOffBalanceReport: builder.mutation<
      ITimeReportResponse,
      PayloadTimeOffBalanceReport
    >({
      query: payload => ({
        url: '/time-management/reports/leave-balance',
        method: 'POST',
        body: payload,
      }),
    }),
    generateOvertimeRequestReport: builder.mutation<
      ITimeReportResponse,
      PayloadTimeOffReport
    >({
      query: payload => ({
        url: '/time-management/reports/overtime-request',
        method: 'POST',
        body: payload,
      }),
    }),
    payrollRegisterStatus: builder.query<TRespGenerateReport, { id: string }>({
      query: ({ id }) => ({
        url: `/payroll/reports/payroll-register-report/${id}/status`,
      }),
    }),
  }),
});

export const {
  useGetDataGenerate1721iQuery,
  usePostGenerate1721iMutation,
  usePostGeneratePayrollRegisterMutation,
  usePostGenerateBPJSKesehatanMutation,
  usePostGenerateIncomeTaxCalculationMutation,
  useGenerate1721a1ReportMutation,
  useGenerateBPJSKetenagakerjaanMutation,
  useGenerateBPJSKetenagakerjaanLanjutanMutation,
  useGenerateCostCenterReportMutation,
  useGenerateBupot2126Mutation,
  useGenerateOvertimeReportMutation,
  useGenerateAttendanceProcessReportMutation,
  useGenerateAttendanceReportMutation,
  useGenerateTimeOffReportMutation,
  useGenerateTimeOffBalanceReportMutation,
  useGenerateOvertimeRequestReportMutation,
  usePayrollRegisterStatusQuery,
} = standardReportApiSlice;
