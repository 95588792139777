import React from 'react';
import { RouteNode } from '../../@types';

const WorkLocationList = React.lazy(
  () => import('../../pages/workLocation/workLocationList')
);
const WorkLocationDetail = React.lazy(
  () => import('../../pages/workLocation/workLocationDetail')
);
const WorkLocationCreate = React.lazy(
  () => import('../../pages/workLocation/workLocationCreate')
);
const WorkLocationUpdate = React.lazy(
  () => import('../../pages/workLocation/workLocationUpdate')
);
const WorkLocationCorrection = React.lazy(
  () => import('../../pages/workLocation/workLocationCorrection')
);

const workLocation: RouteNode[] = [
  {
    label: 'Work Location',
    path: 'work-location',
    component: WorkLocationList,
    child: null,
    requiredPermissions: 'VIEW',
  },
  {
    label: '',
    path: 'work-location/detail/:id',
    component: WorkLocationDetail,
    child: null,
    requiredPermissions: 'VIEW',
  },
  {
    label: '',
    path: 'work-location/create',
    component: WorkLocationCreate,
    child: null,
    requiredPermissions: 'CREATE',
  },
  {
    label: '',
    path: 'work-location/update/:id',
    component: WorkLocationUpdate,
    child: null,
    requiredPermissions: 'UPDATE',
  },
  {
    label: '',
    path: 'work-location/correction/:id',
    component: WorkLocationCorrection,
    child: null,
    requiredPermissions: 'CORRECTION',
  },
];

export default workLocation;
