import React from 'react';
import { RouteNode } from '../../../@types';

const BankFileList = React.lazy(
  () => import('../../../pages/bankFile/BankFileList')
);
const BankFileCreate = React.lazy(
  () => import('../../../pages/bankFile/BankFileCreate')
);

const bankFileRoutes: RouteNode[] = [
  {
    label: 'Bank File',
    path: 'bank-file',
    component: BankFileList,
    child: null,
    requiredPermissions: 'VIEW',
  },
  {
    label: '',
    path: 'bank-file/create',
    component: BankFileCreate,
    child: null,
    requiredPermissions: 'CREATE',
  },
];

export default bankFileRoutes;
