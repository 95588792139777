import React from 'react';
import { RouteNode } from '../../@types';

const Create = React.lazy(() => import('../../pages/employee/address/Create'));

const Corrections = React.lazy(
  () => import('../../pages/employee/address/Correction')
);

const Update = React.lazy(() => import('../../pages/employee/address/Update'));

const Address: RouteNode[] = [
  {
    label: '',
    path: 'employee/detail/:id/address/create',
    component: Create,
    child: null,
    requiredPermissions: 'CREATE',
  },
  {
    label: '',
    path: 'employee/detail/:id/address/correction/:groupId',
    component: Corrections,
    child: null,
    requiredPermissions: 'CORRECTION',
  },
  {
    label: '',
    path: '/employee/detail/:id/address/update/:groupId',
    component: Update,
    child: null,
    requiredPermissions: 'UPDATE',
  },
];

export default Address;
