import React from 'react';
import { PieChart } from '../../components/atom/icon';
import { RouteNode } from '../../@types';

const UnderConstruction = React.lazy(
  () => import('../../pages/UnderConstruction')
);
const Create = React.lazy(() => import('../../pages/customFields/Create'));
const List = React.lazy(() => import('../../pages/customFields/List'));
const Detail = React.lazy(() => import('../../pages/customFields/Detail'));
const Update = React.lazy(() => import('../../pages/customFields/Update'));

export const CustomFields: RouteNode[] = [
  {
    label: 'Custom Fields',
    icon: PieChart,
    child: null,
    path: 'custom-field',
    component: List,
    requiredPermissions: 'VIEW',
  },
  {
    label: '',
    path: 'custom-field/create',
    component: Create,
    child: null,
    requiredPermissions: 'CREATE',
  },
  {
    label: '',
    path: 'custom-field/detail/:id',
    component: Detail,
    child: null,
    requiredPermissions: 'VIEW',
  },
  {
    label: '',
    path: 'custom-field/update/:id',
    component: Update,
    child: null,
    requiredPermissions: 'UPDATE',
  },
];
