import React from 'react';
import { RouteNode } from '../../../@types';

const List = React.lazy(() => import('../../../pages/timeItem/List'));
const Create = React.lazy(() => import('../../../pages/timeItem/Create'));
const Detail = React.lazy(() => import('../../../pages/timeItem/Detail'));
const Update = React.lazy(() => import('../../../pages/timeItem/Update'));

export const timeItem: RouteNode[] = [
  {
    label: 'Time Item',
    child: null,
    path: 'time-items',
    component: List,
    requiredPermissions: 'VIEW',
  },
  {
    label: '',
    child: null,
    path: 'time-items/create',
    component: Create,
    requiredPermissions: 'CREATE',
  },
  {
    label: '',
    path: 'time-items/detail/:id',
    component: Detail,
    child: null,
    requiredPermissions: 'VIEW',
  },
  {
    label: '',
    path: 'time-items/update/:id',
    component: Update,
    child: null,
    requiredPermissions: 'UPDATE',
  },
];
