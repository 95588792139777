import React from 'react';
import { RouteNode } from '../../@types';

const List = React.lazy(() => import('../../pages/costCenter/List'));
const Create = React.lazy(() => import('../../pages/costCenter/Create'));
const Detail = React.lazy(() => import('../../pages/costCenter/Details'));
const Update = React.lazy(() => import('../../pages/costCenter/Update'));
const Correction = React.lazy(
  () => import('../../pages/costCenter/Correction')
);

const costCenter: RouteNode[] = [
  {
    label: 'Cost Center',
    path: 'cost-center',
    component: List,
    child: null,
    requiredPermissions: 'VIEW',
  },
  {
    label: '',
    path: 'cost-center/create',
    component: Create,
    child: null,
    requiredPermissions: 'CREATE',
  },
  {
    label: '',
    path: 'cost-center/detail/:id',
    component: Detail,
    child: null,
    requiredPermissions: 'VIEW',
  },
  {
    label: '',
    path: 'cost-center/update/:id',
    component: Update,
    child: null,
    requiredPermissions: 'UPDATE',
  },
  {
    label: '',
    path: 'cost-center/correction/:id',
    component: Correction,
    child: null,
    requiredPermissions: 'CORRECTION',
  },
];

export default costCenter;
