import { lazy } from 'react';
import { RouteNode } from '../../../@types';

const List = lazy(() => import('../../../pages/schedule/List'));
const Detail = lazy(() => import('../../../pages/schedule/Detail'));
const Create = lazy(() => import('../../../pages/schedule/Create'));
const Update = lazy(() => import('../../../pages/schedule/Update'));
const Correct = lazy(() => import('../../../pages/schedule/Correct'));

export const schedule: RouteNode[] = [
  {
    label: 'Schedule',
    child: null,
    path: 'schedule',
    component: List,
    requiredPermissions: 'VIEW',
  },
  {
    label: '',
    child: null,
    path: 'schedule/detail/:id',
    component: Detail,
    requiredPermissions: 'VIEW',
  },
  {
    label: '',
    child: null,
    path: 'schedule/create',
    component: Create,
    requiredPermissions: 'CREATE',
  },
  {
    label: '',
    child: null,
    path: 'schedule/update/:id',
    component: Update,
    requiredPermissions: 'UPDATE',
  },
  {
    label: '',
    child: null,
    path: 'schedule/correction/:id',
    component: Correct,
    requiredPermissions: 'CORRECTION',
  },
];
