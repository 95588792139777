import React from 'react';
import { RouteNode } from '../../@types';

const RegularPaymentUpdate = React.lazy(
  () => import('../../pages/employee/paymentDetails/RegularPaymentUpdate')
);
const RegularPaymentCreate = React.lazy(
  () => import('../../pages/employee/paymentDetails/RegularPaymentCreate')
);
const OffCyclePaymentCreate = React.lazy(
  () => import('../../pages/employee/paymentDetails/OffCyclePaymentCreate')
);
const OffCyclePaymentUpdate = React.lazy(
  () => import('../../pages/employee/paymentDetails/OffCyclePaymentUpdate')
);

const paymentDetails: RouteNode[] = [
  {
    label: '',
    path: 'employee/detail/:id/payment-details/regular-payment/update',
    component: RegularPaymentCreate,
    child: null,
    requiredPermissions: 'CREATE',
  },
  {
    label: '',
    path: 'employee/detail/:id/payment-details/regular-payment/update/:detailId',
    component: RegularPaymentUpdate,
    child: null,
    requiredPermissions: 'UPDATE',
  },
  {
    label: '',
    path: 'employee/detail/:id/payment-details/offcycle-payment/update',
    component: OffCyclePaymentCreate,
    child: null,
    requiredPermissions: 'CREATE',
  },
  {
    label: '',
    path: 'employee/detail/:id/payment-details/offcycle-payment/update/:detailId',
    component: OffCyclePaymentUpdate,
    child: null,
    requiredPermissions: 'UPDATE',
  },
];

export default paymentDetails;
