import React from 'react';
import { RouteNode } from '../../@types';

const Create = React.lazy(() => import('../../pages/employee/family/Create'));
const Update = React.lazy(() => import('../../pages/employee/family/Update'));
const Correction = React.lazy(
  () => import('../../pages/employee/family/Correction')
);

const family: RouteNode[] = [
  {
    label: '',
    path: 'employee/:id/family-create',
    component: Create,
    child: null,
    requiredPermissions: 'CREATE',
  },
  {
    label: '',
    path: 'employee/:id/family-correction/:groupId',
    component: Correction,
    child: null,
    requiredPermissions: 'CORRECTION',
  },
  {
    label: '',
    path: 'employee/:id/family-update/:groupId',
    component: Update,
    child: null,
    requiredPermissions: 'UPDATE',
  },
];

export default family;
