import { lazy } from 'react';
import { RouteNode } from '../../@types';

const List = lazy(() => import('../../pages/payrollRun/severancePay/List'));
const CreatePayment = lazy(
  () => import('../../pages/payrollRun/severancePay/Create')
);
const DetailsPayment = lazy(
  () => import('../../pages/payrollRun/severancePay/Details')
);
const CreatePlan = lazy(
  () => import('../../pages/payrollRun/severancePay/CreatePlan')
);
const DetailsPlan = lazy(
  () => import('../../pages/payrollRun/severancePay/DetailsPlan')
);

const severancePay: RouteNode[] = [
  {
    label: '',
    path: 'payroll-run/severance-pay',
    child: null,
    component: List,
    requiredPermissions: 'VIEW',
  },
  {
    label: '',
    path: 'payroll-run/severance-pay/payment/create/:id',
    child: null,
    component: CreatePayment,
    requiredPermissions: 'CREATE',
  },
  {
    label: '',
    path: 'payroll-run/severance-pay/payment/detail/:id',
    child: null,
    component: DetailsPayment,
    requiredPermissions: 'VIEW',
  },
  {
    label: '',
    path: 'payroll-run/severance-pay/plan/create',
    child: null,
    component: CreatePlan,
    requiredPermissions: 'CREATE',
  },
  {
    label: '',
    path: 'payroll-run/severance-pay/plan/detail/:id',
    child: null,
    component: DetailsPlan,
    requiredPermissions: 'VIEW',
  },
];

export default severancePay;
